import React, { useState } from "react";
// import { FaTrash, FaEdit } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { FaEdit, FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
// import Loader from "../Shared/Loader/Loader";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { useNavigate } from "react-router";

const AllUsers = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { isLoading, data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () =>
      fetch("https://shihabs-tutorial-backend-1.onrender.com/users").then(
        (res) => res.json()
      ),
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;

    if (
      username === "riajul" ||
      username === "shihab" ||
      username === "Admin001" ||
      username === "Admin002" ||
      username === "Admin003" ||
      username === "Admin004" ||
      username === "Admin005"
    ) {
      if (
        password === "@nothingxd" ||
        password === "boss" ||
        password === "Admin002@01" ||
        password === "Admin002@02" ||
        password === "Admin003@03" ||
        password === "Admin004@04" ||
        password === "Admin005@05"
      ) {
        localStorage.setItem("loggedIn", "true");
        setError("");
        navigate(0);
      } else {
        setError("Wrong password");
      }
    } else {
      setError("Wrong username");
    }

    // if (code === "123456") {
    //   // setMatched(true);
    //   localStorage.setItem("loggedIn", "true");
    // } else {
    //   // setMatched(false);

    // }
  };

  if (isLoading) {
    return <Loader />;
  }

  let loggedIn = localStorage.getItem("loggedIn");

  return (
    <div className="lg:px-52 px-5">
      <form
        onSubmit={onSubmit}
        className={
          loggedIn
            ? "hidden"
            : "flex flex-col relative items-center justify-center w-96 mx-auto py-32 rounded-xl bg-white shadow mt-14"
        }
      >
        <h2 className="text-center text-2xl">Login</h2>
        <input
          type="text"
          placeholder="Username"
          name="username"
          className="input input-bordered w-full max-w-xs mt-2"
          required
        />
        <input
          type="text"
          placeholder="Password"
          name="password"
          className="input input-bordered w-full max-w-xs mt-2"
          required
        />
        {error}
        <button
          type="submit"
          className="mt-2 max-w-xs inline-flex justify-center py-2 px-4 border border-transparent w-full shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Submit
        </button>
      </form>
      {loggedIn ? (
        <>
          <h2 className="text-center text-2xl mb-5">Class: 8</h2>
          <div className="overflow-x-auto">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-black uppercase bg-[#CFF1DD] ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name & Pic
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Reg. num.
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Class
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Mark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter((filteredUser) => {
                      return filteredUser.stClass === "8";
                    })
                    .map((user) => (
                      <tr
                        key={user._id}
                        className="bg-[#F5FCF8] text-black border-b hover:bg-gray-50"
                      >
                        <td className="px-6 py-4">
                          <img
                            className="w-10 h-10 object-cover rounded-full inline-block mr-3"
                            src={user.pp}
                            alt=""
                          />
                          {user.name}
                        </td>
                        <td className="px-6 py-4">{user.registrationNumber}</td>
                        <td className="px-6 py-4">{user.stClass}</td>
                        <td className="px-6 py-4">
                          {user.mark ? (
                            <div className="flex items-center  gap-2">
                              {user.mark}
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#000000] text-white text-lg mr-2 p-2 rounded-full"
                              >
                                <FaEdit />
                              </Link>
                            </div>
                          ) : (
                            <div className="flex items-center  gap-2">
                              "Not Added"
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#36D399] text-white text-md mr-2 p-2 rounded-full"
                              >
                                <FaPlus />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <h2 className="text-center text-2xl mb-5">Class: 9-10</h2>
          <div className="overflow-x-auto">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-black uppercase bg-[#CFF1DD] ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name & Pic
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Reg. num.
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Class
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Mark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter((filteredUser) => {
                      return filteredUser.stClass === "9";
                    })
                    .map((user) => (
                      <tr
                        key={user._id}
                        className="bg-[#F5FCF8] text-black border-b hover:bg-gray-50"
                      >
                        <td className="px-6 py-4">
                          <img
                            className="w-10 h-10 object-cover rounded-full inline-block mr-3"
                            src={user.pp}
                            alt=""
                          />
                          {user.name}
                        </td>
                        <td className="px-6 py-4">{user.registrationNumber}</td>
                        <td className="px-6 py-4">{user.stClass}</td>
                        <td className="px-6 py-4">
                          {user.mark ? (
                            <div className="flex items-center  gap-2">
                              {user.mark}
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#000000] text-white text-lg mr-2 p-2 rounded-full"
                              >
                                <FaEdit />
                              </Link>
                            </div>
                          ) : (
                            <div className="flex items-center  gap-2">
                              "Not Added"
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#36D399] text-white text-md mr-2 p-2 rounded-full"
                              >
                                <FaPlus />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  {users
                    .filter((filteredUser) => {
                      return filteredUser.stClass === "10";
                    })
                    .map((user) => (
                      <tr
                        key={user._id}
                        className="bg-[#F5FCF8] text-black border-b hover:bg-gray-50"
                      >
                        <td className="px-6 py-4">
                          <img
                            className="w-10 h-10 object-cover rounded-full inline-block mr-3"
                            src={user.pp}
                            alt=""
                          />
                          {user.name}
                        </td>
                        <td className="px-6 py-4">{user.registrationNumber}</td>
                        <td className="px-6 py-4">{user.stClass}</td>
                        <td className="px-6 py-4">
                          {user.mark ? (
                            <div className="flex items-center  gap-2">
                              {user.mark}
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#000000] text-white text-lg mr-2 p-2 rounded-full"
                              >
                                <FaEdit />
                              </Link>
                            </div>
                          ) : (
                            <div className="flex items-center  gap-2">
                              "Not Added"
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#36D399] text-white text-md mr-2 p-2 rounded-full"
                              >
                                <FaPlus />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <h2 className="text-center text-2xl mb-5">Class: 11</h2>
          <div className="overflow-x-auto">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-black uppercase bg-[#CFF1DD] ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name & Pic
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Reg. num.
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Class
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Mark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter((filteredUser) => {
                      return filteredUser.stClass === "11";
                    })
                    .map((user) => (
                      <tr
                        key={user._id}
                        className="bg-[#F5FCF8] text-black border-b hover:bg-gray-50"
                      >
                        <td className="px-6 py-4">
                          <img
                            className="w-10 h-10 object-cover rounded-full inline-block mr-3"
                            src={user.pp}
                            alt=""
                          />
                          {user.name}
                        </td>
                        <td className="px-6 py-4">{user.registrationNumber}</td>
                        <td className="px-6 py-4">{user.stClass}</td>
                        <td className="px-6 py-4">
                          {user.mark ? (
                            <div className="flex items-center  gap-2">
                              {user.mark}
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#000000] text-white text-lg mr-2 p-2 rounded-full"
                              >
                                <FaEdit />
                              </Link>
                            </div>
                          ) : (
                            <div className="flex items-center  gap-2">
                              "Not Added"
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#36D399] text-white text-md mr-2 p-2 rounded-full"
                              >
                                <FaPlus />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <h2 className="text-center text-2xl mb-5">Class: 12</h2>
          <div className="overflow-x-auto">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-black uppercase bg-[#CFF1DD] ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name & Pic
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Reg. num.
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Class
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Mark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter((filteredUser) => {
                      return filteredUser.stClass === "12";
                    })
                    .map((user) => (
                      <tr
                        key={user._id}
                        className="bg-[#F5FCF8] text-black border-b hover:bg-gray-50"
                      >
                        <td className="px-6 py-4">
                          <img
                            className="w-10 h-10 object-cover rounded-full inline-block mr-3"
                            src={user.pp}
                            alt=""
                          />
                          {user.name}
                        </td>
                        <td className="px-6 py-4">{user.registrationNumber}</td>
                        <td className="px-6 py-4">{user.stClass}</td>
                        <td className="px-6 py-4">
                          {user.mark ? (
                            <div className="flex items-center  gap-2">
                              {user.mark}
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#000000] text-white text-lg mr-2 p-2 rounded-full"
                              >
                                <FaEdit />
                              </Link>
                            </div>
                          ) : (
                            <div className="flex items-center  gap-2">
                              "Not Added"
                              <Link
                                to={`/add-edit-mark/${user._id}`}
                                className="text-center inline-block bg-[#36D399] text-white text-md mr-2 p-2 rounded-full"
                              >
                                <FaPlus />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AllUsers;
