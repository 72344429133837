import React from "react";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="drawer drawer-mobile lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content bg-[#F1F5F9] py-10 h-screen overflow-auto">
          <Outlet/>
        </div>
        {/* <div className="drawer-side lg:h-[50vh] h-[100vh]">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <ul className="menu p-4 text-base font-medium overflow-y-auto w-80 bg-base-100 text-base-content lg:h-[50vh] h-[100vh]">
            <li className="mb-1">
              <ActiveLink to="/">Home</ActiveLink>
            </li>
            <li className="mb-1">
              <ActiveLink to="/add-mark">Add Mark</ActiveLink>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
