import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader";
import axios from "axios";

const AddMark = () => {
  const { register, handleSubmit } = useForm();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState({});

  const {
    isLoading,
    data: userInfo,
    refetch,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () =>
      fetch(
        `https://shihabs-tutorial-backend-1.onrender.com/user-by-id/${id}`
      ).then((res) => res.json()),
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://shihabs-tutorial-backend-1.onrender.com/user-by-id/${id}`)
      .then(function (response) {
        setUser(response.data);
      });
    setLoading(false);
  }, [id]);

  if (loading || isLoading) {
    return <Loader />;
  }
  const onSubmit = async (info) => {
    fetch(`https://shihabs-tutorial-backend-1.onrender.com/update-user/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success("Mark Added");
          refetch();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <div className="lg:px-52">
      <Link to="/" className="btn mb-2 btn-primary text-white">
        <FaArrowLeft></FaArrowLeft> Back
      </Link>
      <div class="grid items-center lg:grid-cols-2 gap-5">
        <div className="user-info bg-white p-10 rounded-md shadow-md">
          <img
            className="rounded-full w-28 h-28 object-cover border-4"
            src={user?.pp}
            alt=""
          />
          <h2 className="text-2xl">
            <span className="font-bold">Name:</span> {user?.name}
          </h2>
          <h2>
            <span className="font-bold">Institution:</span>{" "}
            {user?.institutionName}
          </h2>
          <h2>
            <span className="font-bold">Mobile Number:</span>{" "}
            {user?.mobileNumber}
          </h2>
          <h2>
            <span className="font-bold">Registration Number:</span>{" "}
            {user?.registrationNumber}
          </h2>
          <h2>
            <span className="font-bold">Class:</span> {user?.stClass}
          </h2>
          <h2>
            <span className="font-bold">Gender:</span> {user?.gender}
          </h2>
          <h2>
            <span className="font-bold">Date-of-Birth:</span> {user?.dob}
          </h2>
          <h2>
            <span className="font-bold">SSC Roll:</span>{" "}
            {user?.sscRoll ? user.sscRoll : "No found"}
          </h2>
          <h2>
            <span className="font-bold">Address:</span> {user?.address}
          </h2>
          <h2 className="bg-accent py-3 text-white text-center rounded-lg font-bold">
            Mark:{" "}
            {userInfo?.mark ? (
              userInfo.mark
            ) : (
              <span className="text-red-700">Not added</span>
            )}
          </h2>
        </div>
        <div className="add-mark-form bg-white p-10 rounded-md shadow-md h-full">
          <div className="mx-auto mb-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control w-full max-w-md mx-auto">
                <label className="label">
                  <span className="label-text">Mark</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered"
                  defaultValue={user?.mark ? user.mark : ""}
                  {...register("mark", { required: true })}
                />
              </div>
              <div className="form-control w-full max-w-md mx-auto">
                <input
                  type="submit"
                  value="Add Mark"
                  className="btn bg-primary text-white mt-3 w-full"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMark;
